import axios from "axios";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { NavLink, useParams } from "react-router-dom";
import Markdown from "marked-react";
import ContentWrapper from "../elements/contentwrapper/contentwrapper";
import GetImageSize from "../utilities/getimagesize/getimagesize";

function News(props:any) {

    const { lang } = useContext(AppContext);
    const [single, SetSingle] = useState<any>(null);
    const [posts, SetPosts] = useState<any>(null);
    const {index} = useParams();
    const {slug} = useParams();

    useEffect(() => {  
        if(slug)
        {
            axios
                .get(process.env.STRAPI_URL + '/api/newsposts/?filters[slug][$eq]=' + slug + '&populate=deep,4&locale=' + lang)
                .then((response) => {
                    SetSingle(response.data.data[0].attributes);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else
        {
            axios
                .get(process.env.STRAPI_URL + '/api/newsposts/?populate=thumbnail&locale=' + lang)
                .then((response) => {
                    SetPosts(response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }, [lang, slug, index]);

    return (
        <div className='w-full bg-neutral-100 py-20'>
            <ContentWrapper>
                {slug && single && 
                    <>
                        <h1 className='font-bold text-3xl md:text-4xl lg:text-5xl mb-12'>{single.title}</h1>
                        {single.content && single.content.map((block:any, index:number) => 
                            <motion.div key={'content-' + index} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
                                <>
                                    {block.__component == 'news.title' && 
                                        <h2 className='font-bold text-3xl md:text-4xl lg:text-5xl mb-12'>{block.content}</h2>
                                    }
                                    {block.__component == 'news.text' && 
                                        <div className='mb-12 markdown'><Markdown>{block.content}</Markdown></div>
                                    }
                                    {block.__component == 'news.image' && 
                                        <div className='mb-12'><img src={GetImageSize(block.content, 'large')} className="w-full" /></div>
                                    }
                                    {block.__component == 'news.video' && block.content.provider == 'youtube' && 
                                        <div className='mb-12'>
                                            <iframe className="w-full aspect-video" src={"https://www.youtube.com/embed/" + block.content.providerUid} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                        </div>
                                    }
                                </>
                            </motion.div>
                        )}
                    </>
                }
                {!slug && posts && 
                    <>
                        <h1 className='font-bold text-3xl md:text-4xl lg:text-5xl mb-12'>News</h1>
                        {posts.map((post:any, index:number) => 
                            <motion.div key={'post-' + index} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
                                <>
                                    <NavLink to={post.attributes.slug} className='flex gap-8 mb-12'>
                                        <div className="flex-shrink-0">
                                            <img src={GetImageSize(post.attributes.thumbnail, 'small')} className="block size-40 object-cover" />
                                        </div>
                                        <div>
                                            <h2 className='font-bold text-lg md:text-xl lg:text-2xl mb-2'>{post.attributes.title}</h2>
                                            <div className="leading-relaxed text-neutral-500">
                                                <Markdown>{post.attributes.intro}</Markdown>
                                            </div>
                                        </div>
                                    </NavLink>
                                </>
                            </motion.div>
                        )}
                    </>
                }
            </ContentWrapper>
        </div>
    );
}

export default News;