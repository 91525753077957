import { useContext, useState, useEffect } from "react";
import Markdown from "react-markdown";
import { AppContext } from "../../App";
import axios from "axios";
import Dots from "../elements/dots/dots";

function Form(props: any) {

    const { lang } = useContext(AppContext);
    const [fields, SetFields] = useState({});
    const [submitted, SetSubmitted] = useState<boolean>(false);
    const [pageTitle, SetPageTitle] = useState<String>('');

    useEffect(() => {  

        if(props.productId != 0)
        {
            axios
                .get(process.env.STRAPI_URL + '/api/products/' + props.productId)
                .then((response) => {
                    SetPageTitle(response.data.data.attributes.name);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if(props.pageId != 0)
        {
            axios
                .get(process.env.STRAPI_URL + '/api/pages/' + props.pageId)
                .then((response) => {
                    SetPageTitle(response.data.data.attributes.title);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }, [lang, props])

    const HandleChange= (event: any) =>
    {
        let newFields = fields;
        newFields[event.target.name] = event.target.value;
        SetFields(newFields);
    }

    const HandleSubmit = (event: any) =>
    {
        SaveFormToDatabase();
        SendFormViaEmail();
        SetSubmitted(true);
        event.preventDefault();
    }

    const SaveFormToDatabase = () =>
    {
        let inquiry = 
        {
            data: 
            {
                form: 
                {
                    connect: [props.formId]
                },
                product: null,
                page: null,
                locale: lang,
                date: new Date(),
                fields: []
            }
        }

        if(props.productId && props.productId != 0) inquiry.data.product = { connect: [props.productId] }
        if(props.pageId && props.pageId != 0) inquiry.data.page = { connect: [props.pageId] }

        Object.keys(fields).forEach(key => {
            inquiry.data.fields.push({ 'label': key, 'value': fields[key] });
        });

        console.log(inquiry);

        axios
            .post(process.env.STRAPI_URL + '/api/inquiries', inquiry)
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const SendFormViaEmail = () =>
    {
        let subject = 'SIC Website: Form submission on ' + pageTitle;

        if(fields.hasOwnProperty('First Name') && fields.hasOwnProperty('Last Name')) subject += ' from ' + fields['First Name'] + ' ' + fields['Last Name'];
        if(fields.hasOwnProperty('Name')) subject += ' from ' + fields['Name'];

        let html = '<html><body>Website form: <br /><br /><strong>Page:</strong> ' + pageTitle + '<br /><strong>Language:</strong> ' + lang.toUpperCase() + '<br /><br />';

        html += '<table>';

        Object.keys(fields).forEach(key => {
            html += '<tr><td valign="top" style="padding-right: 20px"><strong>' + key + ':</strong></td><td valign="top" style="white-space: pre-wrap">' + fields[key] + '</td></tr>';
        });

        html += '</table></body></html>';

        let data = 
        {  
            sender:{  
                name: "SIC",
                email: "marketing@sickft.hu"
            },
            to:[  
                {  
                    name: "Toon Van Wemmel",
                    email: "toonvanwemmel@gmail.com"
                }
            ],
            subject: subject,
            htmlContent: html
        }

        axios
            .post('https://api.brevo.com/v3/smtp/email', data, 
                {
                    headers: 
                    {
                        'accept': 'application/json',
                        'api-key': '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',
                        'content-type': 'application/json'
                    }
                })
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const GetTitleClass = () =>
    {
        if(props.type == 'Small') return 'text-3xl font-bold mb-12';
        return 'text-5xl font-bold mb-4 text-center';
    }

    const GetTextClass = () =>
    {
        if(props.type == 'Small') return 'leading-loose text-neutral-500';
        return 'leading-loose text-center max-w-3xl mx-auto mb-12';
    }

    const GetIntroClass = () =>
    {
        if(props.type == 'Small') return 'text-l mb-4';
        return 'text-l mb-4 text-center';
    }

    const GetFormClass = () =>
    {
        if(props.type == 'Small') return '';
        return 'grid grid-cols-2 gap-x-8';
    }

    const GetFieldWidth = () =>
    {
        if(props.type == 'Small') return ' min-w-96';
        return ' w-full';
    }

    return (
        <>
            <div className={GetIntroClass()}>{props.intro}</div>
            <div className={GetTitleClass()}>{props.title}</div>
            {props.type != 'Small' && 
                <div className="w-fit mx-auto mb-4"><Dots color='blue' /></div>
            }
            <div className={GetTextClass()}><Markdown>{props.text}</Markdown></div>
            {submitted == false &&
                <form className={GetFormClass()} onSubmit={HandleSubmit}>
                    {props.fields.map((field: any, index: number) =>
                        <div key={'field-' + index} className={field.width == 'Full' ? 'col-span-full' : 'col-span-full lg:col-span-1'}>
                            {(field.type == 'Text' || field.type == 'Email') &&
                                <div className="mt-8">
                                    <div className="text-sm mb-2">{field.label} {field.required == true ? '*' : ''}</div>
                                    <input name={field.label} className={"clear-both border border-neutral-300 py-1.5 px-3 rounded-lg" + GetFieldWidth()} type={field.type.toLowerCase()} required={field.required} placeholder={field.placeholder} onChange={HandleChange} />
                                </div>
                            }
                            {field.type == 'Textarea' &&
                                <div className="mt-8">
                                    <div className="text-sm mb-2">{field.label} {field.required == true ? '*' : ''}</div>
                                    <textarea name={field.label} className={"clear-both border border-neutral-300 py-1.5 px-3 rounded-lg min-h-40" + GetFieldWidth()} required={field.required} placeholder={field.placeholder} onChange={HandleChange} />
                                </div>
                            }
                        </div>
                    )}
                    <input type="submit" value={props.buttonLabel} className="cursor-pointer mt-8 font-bold uppercase py-2 px-6 rounded-xl text-sm bg-sic-blue text-white w-fit" />
                </form>
            }
            {submitted &&
                <div className={GetTextClass() + ' font-bold mt-8'}><Markdown>{props.response}</Markdown></div>
            }
        </>
    );
}

export default Form;